"use strict";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import "./ad_layout.css";
import "./ad_layout_left.css";
import "./ad_layout_btf.css";
import "./ad-dev-debug.css";
import { tab_app_google_adsense } from "../../tab_app_info";
import React from "react";
import assert from "@brillout/assert";
import { getAdsenseSlotId, getEzoicSlot, getGptSlot } from "./slots"; // export { Ad_ATF, Ad_BTF };

export { Ad_btf_2 };
export { Ad_left };

function Ad_left(_ref) {
  var ad_slots = _ref.ad_slots;
  var slot_left_atf = get_left_slot("LEFT_AD_ATF", ad_slots);
  var slot_left_btf = get_left_slot("LEFT_AD_BTF", ad_slots);
  assert(slot_left_atf || slot_left_btf);
  return React.createElement("div", {
    id: "ads-left"
  }, slot_left_atf, slot_left_btf);
}

function get_left_slot(slot_name, ad_slots) {
  var content;
  var is_floating;
  {
    var adsense_id = getAdsenseSlotId(slot_name, ad_slots);

    if (adsense_id) {
      content = React.createElement(AdSenseAd, {
        slot_id: adsense_id,
        slotName: slot_name
      });
    }
  }
  {
    var gptSlot = getGptSlot(slot_name, ad_slots);

    if (gptSlot) {
      content = React.createElement(GptAd, {
        gptSlot: gptSlot,
        slotName: slot_name
      });
    }
  }
  {
    var ezoic_slot = getEzoicSlot(slot_name, ad_slots);

    if (ezoic_slot) {
      var ezoic_id = ezoic_slot.slot_id;
      content = React.createElement(EzoicAd, {
        ezoic_id: ezoic_id,
        slotName: slot_name
      });
      is_floating = ezoic_slot.is_floating;
      assert([true, undefined].includes(is_floating));
    }
  }

  if (!content) {
    return null;
  }

  return React.createElement("div", {
    className: "slot-left"
  }, React.createElement("div", {
    className: "vertical-slot-wrapper" + (is_floating ? " is_floating" : "")
  }, content));
}

function Ad_btf_2(_ref2) {
  var ad_slots = _ref2.ad_slots;
  var slot_name = "BTF_2";
  var slot_content;
  {
    var adsense_id = getAdsenseSlotId(slot_name, ad_slots);

    if (adsense_id) {
      slot_content = React.createElement(AdSenseAd, {
        slotName: slot_name,
        slot_id: adsense_id,
        auto_sizing: "horizontal"
      }); // Do no include the container when doing auto_sizing

      return slot_content;
    }
  }
  {
    var gptSlot = getGptSlot(slot_name, ad_slots);

    if (gptSlot) {
      slot_content = React.createElement(GptAd, {
        gptSlot: gptSlot,
        slotName: slot_name
      });
    }
  }
  {
    var ezoic_slot = getEzoicSlot(slot_name, ad_slots);

    if (ezoic_slot) {
      assert(slot_content === undefined);
      var ezoic_id = ezoic_slot.slot_id;
      slot_content = React.createElement(EzoicAd, {
        ezoic_id: ezoic_id,
        slotName: slot_name
      });
    }
  }

  if (!slot_content) {
    return null;
  }

  return React.createElement("div", {
    className: "slot_btf_container"
  }, slot_content);
}
/*
function Ad_ATF({ ad_slots }) {
  return <AdView ad_slots={ad_slots} slot_name={"ATF"} />;
}
function Ad_BTF({ ad_slots }) {
  return <AdView ad_slots={ad_slots} slot_name={"BTF"} />;
}

function AdView({ ad_slots, slot_name }: {ad_slots: AdSlots, slot_name: SlotName}) {
  assert(["ATF", "BTF"].includes(slot_name));
  const slot_id = getAdsenseSlotId(slot_name, ad_slots);
  if (slot_id === null) return null;
  return (
    <div className="advi-wrap">
      <div className="horizontal-slot-wrapper">
        <AdSenseAd slot_id={slot_id} slotName={slot_name} />
      </div>
    </div>
  );
}
 */


function EzoicAd(_ref3) {
  var ezoic_id = _ref3.ezoic_id,
      slotName = _ref3.slotName;
  assert(ezoic_id);
  assert(ezoic_id.startsWith("ezoic-pub-ad-placeholder-1"));
  return React.createElement("div", {
    className: "ezoic-ad-slot slot-" + slotName
  }, React.createElement("div", {
    id: ezoic_id
  }), React.createElement("div", {
    className: "ezoic-dev-demo"
  }));
}

function GptAd(_ref4) {
  var gptSlot = _ref4.gptSlot,
      slotName = _ref4.slotName;
  return React.createElement("div", {
    id: gptSlot.slot_id,
    className: "slot-" + slotName,
    style: {
      width: gptSlot.slotSize[0],
      height: gptSlot.slotSize[1]
    }
  });
}

function AdSenseAd(_ref5) {
  var slot_id = _ref5.slot_id,
      slotName = _ref5.slotName,
      _ref5$auto_sizing = _ref5.auto_sizing,
      auto_sizing = _ref5$auto_sizing === void 0 ? undefined : _ref5$auto_sizing;
  assert(tab_app_google_adsense.startsWith("ca-pub-"));
  assert([undefined, "horizontal"].includes(auto_sizing));
  var props = {};

  if (auto_sizing) {
    Object.assign(props, {
      "data-ad-format": auto_sizing,
      "data-full-width-responsive": true
    });
  }

  return React.createElement("ins", _extends({
    className: "adsbygoogle slot-" + slotName,
    "data-ad-client": tab_app_google_adsense,
    "data-ad-slot": slot_id
  }, props));
}